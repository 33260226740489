import { Badge, Dropdown, MenuProps } from 'antd'
import React from 'react'
import BELL_ICON from '../../assets/icons/noti/bell.svg'
import { Link } from 'react-router-dom'
import useNotificationBell from './useNotificationBell'
import { NotificationBell } from '../../models/Notification'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'

export default function NotiBell() {
  const { count, currentCount, notificationBells } = useNotificationBell()
  const { choosingClient, choosingCompany, choosingWarehouse } = useSelector((state: RootState) => state.account);

  function genToolTip() {
    const tooltips: NotificationBell[] = [];
    notificationBells.forEach(value => {
      if (value.count === 0) return;
      tooltips.push(value);
    })

    const items: MenuProps['items'] = tooltips.map((tooltip, idx) => {
      const {
        client_code_nom = '',
        company_code_nom = '',
        warehouse_code_nom = '',
        count = 0
      } = tooltip;
      const [codeClient, nomClient] = client_code_nom.split('_');
      const [codeCompany, nomCompany] = company_code_nom.split('_');
      const [codeWarehouse, nomWarehouse] = warehouse_code_nom.split('_');
      const item = (
        <div className='text-base'>
          <span>
            <span className='font-bold'>{codeClient}</span>_<span className='text-gray'>{nomClient}</span> |&nbsp;
            <span className='font-bold'>{codeCompany}</span>_<span className='text-gray'>{nomCompany}</span> |&nbsp;
            <span className='font-bold'>{codeWarehouse}</span>_<span className='text-gray'>{nomWarehouse}</span>
          </span>
          :&nbsp;
          <span className='text-red font-bold'>{count}</span>
        </div>

      )
      return {
        key: `${idx}`,
        label: item
      }
    })
    return items
  }
  function getCountDisplay() {
    if (!choosingClient || !choosingCompany || !choosingWarehouse) return count;
    if (count === 0) return '';
    return `${currentCount}-${count}`
  }
  return (
    <Dropdown placement='bottom' menu={{ items: genToolTip() }} >
      <Link to={'notifications'}>
        <Badge count={getCountDisplay()} size={"small"}>
          <img src={BELL_ICON} className="cursor-pointer" alt='noti' />
        </Badge>
      </Link>

    </Dropdown>
  )
}
