import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Fournisseur, Transporteur } from "../../models";
import fournisseurApi, { FilterFournisseurParam } from "../../http/fournisserApi";
import { PagingEnum } from "../../enum";
import { RootState } from "../../app/store";
import transporteurApi from "../../http/transporteurApi";
import destinataireApi from "../../http/destinataireApi";


export type FilterTierParam = {
  offset: number,
  limit: number,
  search?: string,
  client_id?: string | null
}

type TierInitState = {

  query: FilterTierParam
  data: {
    fournisseur: {
      loading: boolean,
      items: Fournisseur[],
      total_records?: number,
    },
    transporteur: {
      loading: boolean,
      items: Transporteur[],
      total_records?: number,
    },
    destinataire: {
      loading: boolean,
      items: Fournisseur[],
      total_records?: number,
    },
  }
}

const initialState: TierInitState = {
  query: {
    offset: PagingEnum.DEFAULT_PAGE_INDEX - 1,
    limit: PagingEnum.DEFAULT_PAGE_SIZE,
    search: '',
    client_id: null
  },
  data: {
    fournisseur: {
      loading: false,
      items: [],

    },
    transporteur: {
      loading: false,
      items: [],

    },
    destinataire: {
      loading: false,
      items: [],

    }
  }

};

export const filterFournisseurAction = createAsyncThunk(
  '/tier/fournisseurs',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const params = state.tier.query;
    const response = (await fournisseurApi.filterFournisseur(params)).data;
    return response;
  }
)

export const filterTransporteurAction = createAsyncThunk(
  '/tier/transporteurs',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const params = state.tier.query;
    const response = (await transporteurApi.filterTransporteur(params)).data;
    return response;
  }
)

export const filterDestinataireAction = createAsyncThunk(
  '/tier/destinataires',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const params = state.tier.query;
    const response = (await destinataireApi.filterDestinataire(params)).data;
    return response;
  }
)

const tierSlice = createSlice({
  name: 'tier',
  initialState,
  reducers: {
    setParamAction(state, action) {
      const query = state.query;
      state.query = {
        ...query,
        ...action.payload
      }
    },
    setPagingAction(state, action) {
      const query = state.query;
      state.query = {
        ...query,
        ...action.payload
      }
      filterFournisseurAction();
    },
    clearParamsAction(state) {
      state.query = {
        offset: PagingEnum.DEFAULT_PAGE_INDEX - 1,
        limit: PagingEnum.DEFAULT_PAGE_SIZE,
        search: '',
        client_id: null
      }

    }
  },
  extraReducers(builder) {
    builder
      .addCase(filterFournisseurAction.pending, (state, action) => {
        state.data.fournisseur.loading = true;
      })
      .addCase(filterFournisseurAction.fulfilled, (state, action) => {
        state.data.fournisseur.loading = false;
        state.data.fournisseur.items = action.payload.entry;
        state.data.fournisseur.total_records = action.payload.total_records
      })
      .addCase(filterFournisseurAction.rejected, (state, action) => {
        state.data.fournisseur.loading = false;
      })

      .addCase(filterTransporteurAction.pending, (state, action) => {
        state.data.transporteur.loading = true;
      })
      .addCase(filterTransporteurAction.fulfilled, (state, action) => {
        state.data.transporteur.loading = false;
        state.data.transporteur.items = action.payload.entry;
        state.data.transporteur.total_records = action.payload.total_records
      })
      .addCase(filterTransporteurAction.rejected, (state, action) => {
        state.data.transporteur.loading = false;
      })

      .addCase(filterDestinataireAction.pending, (state, action) => {
        state.data.destinataire.loading = true;
      })
      .addCase(filterDestinataireAction.fulfilled, (state, action) => {
        state.data.destinataire.loading = false;
        state.data.destinataire.items = action.payload.entry;
        state.data.destinataire.total_records = action.payload.total_records
      })
      .addCase(filterDestinataireAction.rejected, (state, action) => {
        state.data.destinataire.loading = false;
      })
  },
})

const tierReducer = tierSlice.reducer;
export const { setParamAction, setPagingAction, clearParamsAction } = tierSlice.actions;
export default tierReducer;