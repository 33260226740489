import { DestinataireInterface, ModalGetByIdRequest } from '../models'
import { FilterTierParam } from '../redux/reducers/tierSlice'
import { DestinataireArrayInterface } from './../models/Destinataire'
import baseAxios from './clientAPI'

const destinataireApi = {
  getAllDestinataire(): Promise<DestinataireInterface> {
    const url = '/warehouse/destinataire/all'
    return baseAxios.get(url)
  },

  getDestinataireByName(payload?: {
    code: string
  }): Promise<DestinataireInterface> {
    const url = `/warehouse/destinataire/get`
    const params = {
      data: payload?.code,
    }
    return baseAxios.post(url, params)
  },

  createDestinataire(payload = {}): Promise<DestinataireInterface> {
    const url = '/warehouse/destinataire'
    return baseAxios.post(url, payload)
  },

  searchDestinataireByNameOrCode(payload: {
    data: string
    client_id: string
  }): Promise<DestinataireArrayInterface> {
    const url = '/warehouse/destinataire/search_by_nom_code'

    return baseAxios.post(url, payload)
  },
  getDestinataireByIdElement(
    payload: ModalGetByIdRequest
  ): Promise<DestinataireInterface> {
    const url = `/warehouse/destinataire/get-by-id-element?id=${payload.id}&id_contact=${payload.id_contact}&id_add=${payload.id_add}`
    return baseAxios.get(url)
  },

  filterDestinataire(params: FilterTierParam):Promise<DestinataireArrayInterface>{
    const url = '/warehouse/destinataire/list';
    return baseAxios.get(url, {params});
  }
}

export default destinataireApi
