import Define from '../constants/define'
import {
  Attendu,
  AttenduInterface,
  SingleFileResponseInterface,
  TotalAccessoriesApi,
  ExportAttenduType,
  ExportResponseInterface,
  AttenduDeleteInterface,
  AttenduArrayInterface,
} from '../models'
import baseAxios from './clientAPI'
import { getUrlFilterParams } from '../utils'

export type AttenduCreatePayload = {
  body: Attendu
  realTimeData: string
}

const attenduApi = {
  createAttendu({
    body,
    realTimeData,
  }: AttenduCreatePayload): Promise<AttenduInterface> {
    const uri = '/warehouse/attendu/'
    return baseAxios.post(uri, { attendu: body, realtime_object: realTimeData })
  },

  getAllAttendu(payload?: {
    limit?: number
    offset?: number
  }): Promise<AttenduArrayInterface> {
    const url = '/warehouse/attendu/all'
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    const params = {
      limit: payload?.limit || pageSize,
      offset: payload?.offset || (pageIndex - 1) * pageSize || 0,
      user_id: localStorage.getItem(Define.USER_ID),
      role: localStorage.getItem(Define.ROLE),
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      ...condition,
    }
    return baseAxios.get(url, { params })
  },

  getTotalAttendu(payload: {}): Promise<TotalAccessoriesApi> {
    const url = '/warehouse/accessories/get-total'
    const params = {
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      type: 'attendu',
    }
    return baseAxios.get(url, { params })
  },

  getAttenduById(id: string): Promise<AttenduInterface> {
    const url = `/warehouse/attendu/${id}`
    return baseAxios.get(url)
  },

  editAttendu(body: Attendu, realTimeData: string): Promise<string> {
    const url = '/warehouse/attendu/'
    return baseAxios.put(url, { attendu: body, realtime_object: realTimeData })
  },

  deleteAttenduById(id: string): Promise<AttenduDeleteInterface> {
    const url = `/warehouse/attendu/${id}?user_id=${
      localStorage.getItem(Define.USER_ID) || ''
    }`
    return baseAxios.delete(url)
  },

  printInforAtt(attProId: string): Promise<SingleFileResponseInterface> {
    const url = `/warehouse/attendu/print-infor-att?att_production_id=${attProId}`
    return baseAxios.get(url)
  },

  printAllSscc(attProIds: string[]): Promise<SingleFileResponseInterface> {
    const url = `/warehouse/attendu/gen-all-sscc`
    const body = { att_production_ids: attProIds }
    return baseAxios.post(url, body)
  },

  fixReserve(data: { id: string; is_block: boolean }) {
    const url = `/warehouse/reserve/${data.id}`
    return baseAxios.put(url, { entry: data })
  },

  updateReservesData(data: Array<{ id: string; comment: string }>) {
    const url = '/warehouse/reserve'
    return baseAxios.put(url, { entry: data })
  },

  exportAllAttendu(data: ExportAttenduType): Promise<ExportResponseInterface> {
    const url = '/warehouse/attendu/export'
    return baseAxios.post(url, data)
  },

  checkBeforeDeleteAttendu(attenduId: string): Promise<AttenduInterface> {
    const url = `/warehouse/attendu/get-delete-status?id=${attenduId}`
    return baseAxios.get(url)
  },
}

export default attenduApi
