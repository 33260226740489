import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Attendu,
  AttenduArrayInterface,
  Conditionnement,
  ReferenceAttendu,
  TotalAccessoriesApi,
} from '../../models'
import { StatusAPI } from '../../enum'
import { Metadata } from '../../models'
import { ActionRealtimeType } from '../../realtime/type'
import { AttenduCreatePayload } from '../../http/attenduApi'
import { cloneDeep } from 'lodash'
import { StatusAttendu } from '../../enum/StatusAttendu'

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface currentAttenduObject {
  status: StatusAPI
  attendu?: Attendu
  error: string
}

interface currentAttenduList {
  status: StatusAPI
  attenduList: Attendu[]
  loading: boolean
  metadata: Metadata
}

interface totalAttenduList {
  status: StatusAPI
  number: number
  loading: boolean
}

interface DetailSection {
  validAtLeastOneLine?: boolean
  validAtLeastOneLineInEditMode?: boolean
}
export interface attenduState {
  detail: DetailSection
  currentAttendu: currentAttenduObject
  currentListAttendu: currentAttenduList
  totalAttendu: totalAttenduList
  error: string
}

const initialState: attenduState = {
  detail: {
    validAtLeastOneLine: false,
    validAtLeastOneLineInEditMode: false,
  },
  currentAttendu: {
    status: StatusAPI.initial,
    attendu: undefined,
    error: '',
  },
  currentListAttendu: {
    status: StatusAPI.initial,
    attenduList: [],
    loading: false,
    metadata: {
      count: 0,
      limit: 0,
      total: 0,
    },
  },
  totalAttendu: {
    status: StatusAPI.initial,
    number: 0,
    loading: false,
  },
  error: '',
}

const attenduSlice = createSlice({
  name: 'attendu',
  initialState,
  reducers: {
    initialAttendu(state) {
      state.currentAttendu = initialState.currentAttendu
    },
    clearList(state) {
      state.currentListAttendu = initialState.currentListAttendu
    },
    clearTotalNumber(state) {
      state.totalAttendu = initialState.totalAttendu
    },
    //Create Attendu
    createAttendu(state, action: PayloadAction<AttenduCreatePayload>) {
      state.currentAttendu.status = StatusAPI.calling
    },
    createAttenduSuccess(state, action: PayloadAction<Attendu>) {
      state.currentAttendu.status = StatusAPI.success
      state.currentAttendu.attendu = action.payload
    },
    createAttenduFailed(state, action: PayloadAction<string>) {
      state.currentAttendu.status = StatusAPI.failure
      state.currentAttendu.error = action.payload
    },
    //getAll Attendu
    getAllAttendu(state, action: PayloadAction<{ isRealTime: boolean }>) {
      state.currentListAttendu.status = StatusAPI.calling
      state.currentListAttendu.loading = !action.payload.isRealTime
    },
    getAllAttenduSuccess(state, action: PayloadAction<AttenduArrayInterface>) {
      state.currentListAttendu.status = StatusAPI.success
      state.currentListAttendu.attenduList = action.payload.data.entry
      if (state.currentListAttendu.attenduList) {
        state.currentListAttendu.attenduList.forEach((item) => {
          if (!item?.att_production) return
          item.att_production.forEach((item) => {
            if (!item?.reference_product) return
            if (!item.reference_product?.blocage_entree) return
            if (
              ![StatusAttendu.COMPLETE].includes(
                item.status || StatusAttendu.UNKNOWN
              )
            ) {
              return
            }
            item.reference_product.blocage_entree = false
            if (!item.reference_product?.pre_block_status) return
            const pre_block_status = JSON.parse(
              item.reference_product.pre_block_status
            )
            pre_block_status.blocage_entree = false
            item.reference_product.pre_block_status =
              JSON.stringify(pre_block_status)
          })
        })
      }

      state.currentListAttendu.loading = false
      state.currentListAttendu.metadata = action.payload.data.metadata
    },
    getAllAttenduFailed(state, action: PayloadAction<any>) {
      state.currentListAttendu.status = StatusAPI.failure
      state.error = action.payload
      state.currentListAttendu.loading = false
    },
    setAttenduListRealtime(
      state,
      action: PayloadAction<{
        attenduChange: Attendu
        actionType: ActionRealtimeType
      }>
    ) {},
    updateAttenduList(
      state,
      action: PayloadAction<{
        attenduChange: Attendu
        actionType: ActionRealtimeType
        totalRecord?: number
      }>
    ) {
      let data = state.currentListAttendu.attenduList
      const currentDataCount = state.currentListAttendu.metadata.total
      const searchParams = new URLSearchParams(window.location.search)

      function deleteRow() {
        // decrease total attendu number
        state.totalAttendu.number = state.totalAttendu.number
          ? state.totalAttendu.number - 1
          : 0
        // update number of records in current page of attendu
        state.currentListAttendu.metadata.total =
          action.payload.totalRecord || 0
        // remove deleted attendu from list if it is not full and deleted record is found in current list
        if (currentDataCount > (action.payload.totalRecord || 0)) {
          const index = data.findIndex(
            (item) => item.id === action.payload.attenduChange.id
          )
          if (data?.length && index !== -1) {
            data.splice(index, 1)
            state.currentListAttendu.attenduList = data
          }
        }
      }

      switch (action.payload.actionType) {
        case ActionRealtimeType.UPDATE:
          if (action.payload.attenduChange?.deleted_at) {
            deleteRow()
          } else {
            const index = data.findIndex(
              (item) => item.id === action.payload.attenduChange.id
            )
            if (data?.length && index !== -1) {
              // overwrite the data of updated attendu except chrono
              data[index] = {
                ...action.payload.attenduChange,
                chrono: data[index].chrono,
                att_production: action.payload.attenduChange.att_production
                  .filter((item) => !item.deleted_at)
                  .map((att) => {
                    return { ...att, conditionnement: att.conditionnement_ob }
                  }),
              }
              state.currentListAttendu.attenduList = data
            }
          }
          break
        case ActionRealtimeType.CREATE:
          // increase total attendu number
          state.totalAttendu.number = state.totalAttendu.number
            ? state.totalAttendu.number + 1
            : 1
          // update number of records in current page of attendu
          if (
            action.payload.totalRecord &&
            currentDataCount < action.payload.totalRecord
          ) {
            state.currentListAttendu.metadata.total++
          }
          // add new attendu to list if it is not full and new record is fit to current filter condition
          if (
            (!data ||
              data?.length < parseInt(searchParams.get('page-size') || '0')) &&
            action.payload.totalRecord &&
            (!currentDataCount || currentDataCount < action.payload.totalRecord)
          ) {
            const newData = {
              ...action.payload.attenduChange,
              att_production: action.payload.attenduChange.att_production.map(
                (att) => {
                  return { ...att, conditionnement: att.conditionnement_ob }
                }
              ),
            }
            if (data) {
              data.push(newData)
            } else {
              data = [newData]
            }
            state.currentListAttendu.attenduList = data
          }
          break
        case ActionRealtimeType.DELETE:
          deleteRow()
          break
      }
    },
    //get All Attendu without filter condition
    getTotalAttendu(state, action: PayloadAction) {
      state.totalAttendu.status = StatusAPI.calling
      state.totalAttendu.loading = true
    },
    getTotalAttenduSuccess(state, action: PayloadAction<TotalAccessoriesApi>) {
      state.totalAttendu.status = StatusAPI.success
      state.totalAttendu.number = action.payload.data.total
      state.totalAttendu.loading = false
    },
    getTotalAttenduFailed(state, action: PayloadAction<any>) {
      state.totalAttendu.status = StatusAPI.failure
      state.error = action.payload
      state.totalAttendu.loading = false
    },
    getAttenduByName(state) {
      state.currentListAttendu.status = StatusAPI.calling
    },
    getAttenduByNameSuccess(state, action: PayloadAction<Attendu>) {
      state.currentAttendu.status = StatusAPI.success
      state.currentAttendu.attendu = action.payload
    },
    getAttenduByNameFailed(state, action: PayloadAction<any>) {
      state.currentAttendu.status = StatusAPI.failure
      state.error = action.payload
    },
    changeDetailSection(state, action: PayloadAction<DetailSection>) {
      state.detail.validAtLeastOneLine = action.payload?.validAtLeastOneLine
    },
    // update all att_production related to reference when reference is updated
    updateReferenceAttendu(state, action: PayloadAction<any>) {
      state.currentListAttendu.attenduList =
        state.currentListAttendu.attenduList?.map((item) => {
          return {
            ...item,
            att_production: item.att_production?.map((attProduction) => {
              if (attProduction.reference_id !== action.payload.id) {
                return attProduction
              } else {
                const cdnData = action.payload.conditionnement?.find(
                  (cdn: Conditionnement) =>
                    cdn.niveau === attProduction.conditionnement?.niveau
                )
                return {
                  ...attProduction,
                  conditionnement: cdnData,
                  reference_product: action.payload,
                }
              }
            }),
          }
        })
    },
    // update att_production when att_production is updated
    updateAttenduAttProduction(state, action: PayloadAction<ReferenceAttendu>) {
      for (let i = 0; i < state.currentListAttendu.attenduList?.length; i++) {
        const item = state.currentListAttendu.attenduList[i]
        const AttProductionChangeIndex = item.att_production?.findIndex(
          (attProduction) => attProduction.id === action.payload.id
        )
        if (item.att_production?.length && AttProductionChangeIndex >= 0) {
          if (action.payload.deleted_at) {
            state.currentListAttendu.attenduList[i].att_production.splice(
              AttProductionChangeIndex,
              1
            )
          } else {
            const currentData = cloneDeep(
              state.currentListAttendu.attenduList[i]?.att_production?.[
                AttProductionChangeIndex
              ]
            )
            state.currentListAttendu.attenduList[i].att_production[
              AttProductionChangeIndex
            ] = {
              ...currentData,
              ...action.payload,
              conditionnement:
                action.payload.conditionnement_ob ||
                currentData.conditionnement,
            }
          }

          break
        }
      }
    },
  },
})

const attenduReducer = attenduSlice.reducer
export default attenduReducer

export const {
  initialAttendu,
  getAllAttendu,
  getAllAttenduSuccess,
  getAllAttenduFailed,
  getAttenduByName,
  getAttenduByNameSuccess,
  getAttenduByNameFailed,
  createAttendu,
  createAttenduSuccess,
  createAttenduFailed,
  changeDetailSection,
  getTotalAttenduSuccess,
  getTotalAttenduFailed,
  getTotalAttendu,
  setAttenduListRealtime,
  updateAttenduList,
  clearList,
  clearTotalNumber,
  updateReferenceAttendu,
  updateAttenduAttProduction,
} = attenduSlice.actions
