import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PermissionList } from '../../models/Permission'
import { UserRole } from '../../enum/UserRole'
import { RoleTemplate } from '../../models'
import { resetPermissionList } from '../../utils/permissions'

interface PermissionState {
  role: UserRole
  permissionList: PermissionList
  roleTemplates: RoleTemplate[]
}

export const initPermissionList: PermissionList = {
  stock: {
    read: true,
    update: true,
    export: true,
  },
  attendu: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  commande: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  reference: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  mission: {
    read: true,
    upsert_stock: true,
    upsert_attendu: true,
    upsert_commande: true,
    delete_stock: true,
    delete_attendu: true,
    delete_commande: true,
  },
  history: {
    read: true,
  },
  client: {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: true,
  },
  user: {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: true,
  },
  company: {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: true,
  },
  warehouse: {
    read: true,
  },
  billing: {
    read: true,
  },
  inventory: {
    read: true,
  },
}

const initialState: PermissionState = {
  role: UserRole.SUPERADMIN,
  permissionList: initPermissionList,
  roleTemplates: [],
}

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermissions(state, action: PayloadAction<any>) {
      state.permissionList = action.payload.permissionList
    },
    setRole(state, action: PayloadAction<any>) {
      state.role = action.payload.role
    },
    setRoleTemplates(state, action: PayloadAction<any>) {
      state.roleTemplates = action.payload
    },
    resetPermissions(state) {
      resetPermissionList(state.permissionList)
    },
  },
})

const permissionReducer = permissionSlice.reducer

export default permissionReducer

export const { setPermissions, setRole, setRoleTemplates, resetPermissions } =
  permissionSlice.actions
